<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
            :label="$t('Inventory documents')"
            text-class="text-h6"
        />

        <q-space/>

        <q-btn
            :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
            text-color="white"
            size="sm"
            class="q-mr-sm"
            :label="filterBtnText"
            no-caps
            unelevated
            @click="toggleFilters"
        />

        <q-btn
            color="dark"
            text-color="white"
            :label="$t('Refresh')"
            size="sm"
            class="q-mr-sm"
            no-caps
            unelevated
            @click="refreshItems"
        />

        <q-btn
            v-if="!isClient"
            color="light-blue-9"
            text-color="white"
            icon="add"
            size="sm"
            no-caps
            unelevated
            @click="createInventory"
        />
      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <filter-collapse
            :is-open="isOpenFilter"
            :options="{
            defaultFilter: serverParams.filter,
            fields: activatedFields,
            values: {
              states: states
            }
          }"
            @submit="handleFiltersSubmit"
            @close="toggleFilters"
        />
      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <q-table
            style="height: calc(100vh - 130px);"
            class="sticky-header-table"
            row-key="id"
            :rows-per-page-label="$t('Rows per page')"
            :rows="inventoryDocuments"
            :columns="columns"
            v-model:pagination="pagination"
            :loading="inventoryDocumentsLoading"
            :filter="filter"
            virtual-scroll
            binary-state-sort
            flat
            @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
                showing
                color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
                :props="props"
                class="clickable"
                @dblclick="onRowClick(props.row)"
            >
              <q-td
                  key="number"
                  :props="props"
                  auto-width
              >
                {{ props.rowIndex + 1 }}
              </q-td>


              <q-td
                  key="id"
                  :props="props"
              >
                <strong>{{ props.row.id }}</strong>

                <br>

                {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
              </q-td>

              <q-td
                  key="state"
                  :props="props"
              >
                <q-badge
                    :color="stateColors[props.row.state]"
                    text-color="dark"
                    class="q-pa-sm"
                >
                  {{ $t(props.row.state) }}
                </q-badge>
              </q-td>

<!--              <q-td-->
<!--                  key="info"-->
<!--                  :props="props"-->
<!--              >-->
<!--                <strong>{{ props.row.type }}</strong>-->

<!--              </q-td>-->

              <q-td
                  key="extId"
                  :props="props"
              >
                <div v-if="props.row.extId">
                  <strong> {{ props.row.extId }}</strong>
                </div>

                <div v-else>
                  {{'-'}}
                </div>

              </q-td>

              <q-td
                  key="warehouse"
                  :props="props"
              >
                <div v-if="props.row._embedded.clientWarehouse">

                  <strong>{{ props.row._embedded.clientWarehouse.name }} ({{ props.row._embedded.clientWarehouse.id }})</strong>

                </div>
                <div v-else-if="props.row._embedded.warehouse">

                  <strong>{{ props.row._embedded.warehouse.name }} ({{ props.row._embedded.warehouse.id }})</strong>

                </div>
                <div v-else>
                  {{'-'}}
                </div>
              </q-td>

              <q-td
                  key="comment"
                  :props="props"
                  style="max-width: 250px; white-space: pre-wrap !important;"
              >
                {{ props.row.comment || '' }}
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Mixins
import TableMixin from '../../components/global/TableMixin'

// Utils
import { buildQuery } from '../../utils/query-utils'

// Components
import FilterCollapse from '../../components/filters/FilterCollapse.vue'

export default {
  name: 'InventoryDocuments',
  components: {
    FilterCollapse,
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      isOpenFilter: false,
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        // {
        //   label: this.$t('Type'),
        //   name: 'info',
        //   align: 'left'
        // },
        {
          label: this.$t('ExtId'),
          name: 'extId',
          align: 'left'
        },
        {
          label: this.$t('Warehouse'),
          name: 'warehouse',
          align: 'left'
        },
        {
          label: this.$t('Comment'),
          name: 'comment',
          align: 'left'
        }
      ],
      stateColors: {
        new: 'grey-4',
        active: 'amber',
        confirmed: 'teal',
        inactive: 'grey-4',
        deleted: 'danger'
      },
      activatedFields: [
        'id',
        'created.from',
        'created.to',
        'warehouse',
        'state',
        'extId'
      ],
      states: [
        { id: 'new', title: 'New' },
        { id: 'active', title: 'Active' },
        { id: 'confirmed', title: 'Confirmed' },
        { id: 'inactive', title: 'Inactive' },
        { id: 'deleted', title: 'Deleted' }
      ],
      entityClass: 'Orderadmin\\Storage\\Entity\\Movement\\Inventory'
    }
  },
  computed: {
    ...mapGetters([
      'inventoryDocuments',
      'totalInventoryDocumentsNumber',
      'inventoryDocumentsLoading',
      'appOptions',
      'isClient'
    ]),
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
          ? this.$t('Filtered: ' + this.totalInventoryDocumentsNumber)
          : this.$t('Filter')
    }
  },
  mounted () {
    this.loadDefaultItems()
  },
  methods: {
    ...mapActions([
      'saveInventoryDocumentByPayload',
      'loadInventoryDocumentDocument',
      'loadInventoryDocuments',
    ]),
    ...mapMutations([
      'setInventoryDocument',
      'setInventoryDocument',
      'addErrorNotification'
    ]),
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    handleFiltersSubmit (filter) {
      this.isOpenFilter = false
      return this.onRequest({ pagination: { page: 1, filter } })
    },
    toggleFilters () {
      this.isOpenFilter = !this.isOpenFilter
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      this.updateParams(query)

      return this.loadInventoryDocuments(this.serverParams)
          .then(({ page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }
          })
    },
    loadDefaultItems () {
      return this.onRequest({ pagination: {} })
    },
    onRowClick (row) {
      this.setInventoryDocument(row)
      this.$router.push('/documents/inventory-documents/entity/' + row.id)
    },
    createInventory () {
      this.$router.push('/documents/inventory-documents/entity/')
    }
  }
}
</script>
